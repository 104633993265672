import React                from 'react'
import Img                  from "gatsby-image"
import {
    map,
    reduce
}                           from 'lodash-es'
import {
    Button,
    Container,
    Col,
    Row
}                           from 'react-bootstrap'
import {
    FaClock,
    FaCheck,
    FaMobileAlt,
}                           from 'react-icons/fa'
import moment               from 'moment'
import cx                   from 'utils/classnames'
import Icon                 from 'entrada-ui/Icon'
import RawHtml              from 'entrada-ui/RawHtml'
import Typography           from 'entrada-ui/Typography'
import { FareCodeToText }   from 'components/BookingWizard'
import CartHandler          from 'components/CartHandler'
import Modal                from 'components/Modal'
import styles               from './styles.module.scss'

const CartItem = (props) => {

    const {
        id: cartItemId,
        tourName,
        departureDate,
        tourDurationHours,
        tourDurationMinutes,
        cardImage,
        pickup,
        dropoff,
        fares,
        total
    } = props

    const context = React.useContext(CartHandler.Context)
    const { removeFromCart } = context

    const [showSubmitError, setShowSubmitError] = React.useState(false)
    const [showConfirmRemove, setShowConfirmRemove] = React.useState(false)

    const passengersComposition = reduce(
        fares,
        (acc, numberOfPassengers, fareCode) => {
            // - Generate base price description based on the composition and price
            acc.push(
                `${numberOfPassengers} ${FareCodeToText(fareCode, numberOfPassengers > 1)}`
            )

            return acc
        },
        []
    )

    let duration = ''
    if(tourDurationHours) {
        duration += `${tourDurationHours} hour${tourDurationHours > 1 ? 's' : ''}`
    }

    if(tourDurationMinutes) {
        duration += ` ${tourDurationMinutes} minute${tourDurationMinutes > 1 ? 's' : ''}`
    }

    const handleConfirmRemove = () => {
        setShowSubmitError(false)
        setShowConfirmRemove(false)

        removeFromCart(cartItemId)
        .then(() => {

        }).catch((err) => {
            setShowSubmitError(true)
        })
    }

    const handleClickRemove = () => {
        setShowConfirmRemove(true)
    }

    return (
        <div className={styles.item}>
            <Container className={styles.itemContainer}>
                <Row>

                    <Col xs={4} md={3} className='pr-0'>
                        <Img
                            fluid={{
                                ...cardImage,
                                aspectRatio: 4/3
                            }}
                        />
                    </Col>

                    <Col xs={8} md={6}>
                        <div className={styles.departureDate}>{moment(departureDate).format('DD MMM, YYYY')}</div>
                        <Typography className={styles.itemTitle} variant="h4" color="brand-color">
                            {tourName}
                        </Typography>
                        {pickup &&
                        <div className='mb-0'><b>Departs:</b> {pickup.time} at {pickup.description}</div>
                        }
                        {dropoff &&
                        <div className='mb-0'><b>Return:</b> {dropoff.time} at {dropoff.description}</div>
                        }
                    </Col>

                    <Col md={3} className='text-right d-none d-md-block pl-0'>
                        <div className={styles.total}>NZ ${total.toFixed(2)}</div>
                        <div>({passengersComposition.join(', ')})</div>
                    </Col>
                    <Col xs={12}>
                        <hr/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={7} md={12} className='pr-0'>
                        <ul className={styles.features}>
                            <li>
                                <Icon className={styles.featureIcon} fontSize="small">
                                    <FaCheck />
                                </Icon>
                                {/* Free cancellation before {moment(departureDate).subtract(1, 'day').format('DD MMM, YYYY')} */}
                                Free cancellation up to 2 hours before departure
                            </li>
                            {duration && <li>
                                <Icon className={styles.featureIcon} fontSize="small">
                                    <FaClock />
                                </Icon>
                                {duration} (Approx)
                            </li>}
                            {/* Not Needed */}
                            {/* <li>
                                <Icon className={styles.featureIcon} fontSize="small">
                                    <FaMobileAlt />
                                </Icon>
                                Mobile ticket
                            </li> */}
                        </ul>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}><hr/></Col>
                </Row>

                <div className='text-right'>
                    <a href="#" onClick={() => handleClickRemove(cartItemId)}>Remove</a>
                </div>
            </Container>

            <Modal
                show={showConfirmRemove}
                onHide={() => setShowConfirmRemove(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Are you sure you want to remove this tour?<br/>
                        This action cannot be undone.
                    </Modal.Title>
                </Modal.Header>

                <Modal.Footer className="d-flex justify-content-end">
                    <Button variant="primary" className={styles.nopadd} onClick={() => setShowConfirmRemove(false)}>Go back</Button>
                    <Button variant="danger" onClick={handleConfirmRemove}>Yes, remove</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showSubmitError}
                onHide={() => setShowSubmitError(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Sorry, something went wrong! You can go back and try again
                    </Modal.Title>
                </Modal.Header>

                <Modal.Footer className="d-flex justify-content-end">
                    <Button onClick={handleClickRemove}>Try again</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CartItem
